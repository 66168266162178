import React from "react";

function KitchenText() {
  return (
    <>
      <span>
        Es momento de elegir la imagen
        <br />
        más bonita que tengas de la cocina.
      </span>
    </>
  );
}

export default KitchenText;
