import React from "react";
import { Link } from "react-router-dom";

function createAccountInfo() {
  return (
    <>
      <div className="pt-[105px] px-8 pb-24">
        {/* TOP DETAILS */}
        <div className="mb-16">
          {/* HEADING */}
          <h2 className="text-[36px] leading-[24px] font-bold mb-6 text-center">
            Información
          </h2>

          {/* DESC */}
          <p className="text-[24px] leading-[26px] font-medium text-center">
            Datos de contacto
          </p>
        </div>

        {/* FORM */}
        <form className="flex flex-col mb-[106px]">
          {/* INPUT FIELDS */}
          <div className="space-y-6 mb-6">
            <input
              className="w-full py-2.5 px-3 border-t-0 border-l-0 border-r-0 border-b-2 border-white bg-transparent placeholder:text-myPurple-200 placeholder:opacity-50 focus-visible:outline-0"
              placeholder="Name"
              type="text"
            />
            <input
              className="w-full py-2.5 px-3 border-t-0 border-l-0 border-r-0 border-b-2 border-white bg-transparent placeholder:text-myPurple-200 placeholder:opacity-50 focus-visible:outline-0"
              placeholder="Email"
              type="email"
            />
            <input
              className="w-full py-2.5 px-3 border-t-0 border-l-0 border-r-0 border-b-2 border-white bg-transparent placeholder:text-myPurple-200 placeholder:opacity-50 focus-visible:outline-0"
              placeholder="Whatsapp"
              type="number"
            />
          </div>

          {/* RADIO-BUTTONS */}
          <div className="flex items-center justify-between px-[18px] mb-9">
            <div className="flex items-center">
              <input
                name="info-radio-button"
                id="Soy asesor"
                type="radio"
                value=""
                className="w-6 h-6 text-myBlack bg-transparent rounded-full border-2 border-myPurple-200 focus:ring-transparent focus:ring-offset-0 checked:border-myPurple-200"
              />
              <label
                htmlFor="Soy asesor"
                className="ml-2.5 text-[16px] leading-[24px]"
              >
                Soy asesor
              </label>
            </div>

            <div className="flex items-center">
              <input
                name="info-radio-button"
                id="Soy propietario"
                type="radio"
                value=""
                className="w-6 h-6 text-myBlack bg-transparent rounded-full border-2 border-myPurple-200 focus:ring-transparent focus:ring-offset-0 checked:border-myPurple-200"
              />
              <label
                htmlFor="Soy propietario"
                className="ml-2.5 text-[16px] leading-[24px]"
              >
                Soy propietario
              </label>
            </div>
          </div>

          {/* BOTTOM DETAILS */}
          <div className="mb-16">
            {/* TITLE */}
            <p className="text-[24px] leading-[26px] font-medium text-center mb-6">
              Inmobiliaria
            </p>

            {/* INPUT FIELDS */}
            <div className="space-y-6">
              <input
                className="w-full py-2.5 px-3 border-t-0 border-l-0 border-r-0 border-b-2 border-white bg-transparent placeholder:text-myPurple-200 placeholder:opacity-50 focus-visible:outline-0"
                placeholder="Nombre de Inmobiliaria"
                type="text"
              />
              <input
                className="w-full py-2.5 px-3 border-t-0 border-l-0 border-r-0 border-b-2 border-white bg-transparent placeholder:text-myPurple-200 placeholder:opacity-50 focus-visible:outline-0"
                placeholder="Página web de la inmobiliaria"
                type="text"
              />
            </div>
          </div>

          {/* BUTTON */}
          <div className="px-7">
            <Link to="/createAccountProfile">
              <button className="bg-myPurple-200 w-full h-[45px] text-[20px] leading-7 text-myBlack font-bold rounded-[18px]">
              Continuar
              </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default createAccountInfo;
