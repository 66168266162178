import React from "react";

function ExteriorText() {
  return (
    <>
      <span>
        ¡Ya casi terminamos!
        <br />
        Elige hasta dos imágenes de los exteriores o amenidades.
      </span>
    </>
  );
}

export default ExteriorText;
