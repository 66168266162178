import React from "react";
import demo_profile_img from "../images/demo_profile.png";
import { Link } from "react-router-dom";

function CreateAccountProfilePicture() {
  return (
    <>
      <div>
        <div className="pt-[185px] px-8 pb-24">
          {/* USER IMAGE */}
          <div className="w-[151.81px] h-[151.81px] bg-gradient-profile rounded-full m-auto flex items-center justify-center p-[2.5px] mb-11">
            <div className="w-full h-full bg-[#464646] rounded-full border-[5.5px] border-myBlack">
              <img
                className="w-full h-full object-cover rounded-full"
                src={demo_profile_img}
                alt="demo-img"
              />
            </div>
          </div>

          {/* TEXT */}
          <h2 className="text-center text-[36px] leading-6 font-bold mb-6">
            ¡Hola, Ana!
          </h2>
          <p className=" text-[24px] leading-7 font-medium text-center">
            Es momento de elegir una foto de perfil
          </p>

          {/* BUTTON */}
          <div className="mt-[134px]">
            <div className="px-7 flex flex-col gap-y-3">
              <Link to="/profileWP">
                <button className="bg-myBlack w-full h-[45px] text-[20px] leading-7 text-myPurple-200 font-bold border-2 border-myPurple-200 rounded-[18px]">
                  Omitir
                </button>
              </Link>

              <Link to="/createAccountProfile">
                <button className="bg-myPurple-200 w-full h-[45px] text-[20px] leading-7 text-myBlack font-bold rounded-[18px]">
                Abrir biblioteca
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateAccountProfilePicture;
