import React from "react";
import Header from "../components/Common/Header";
import home_cover_img from "../images/home_cover_img.jpg";
import bedrooms_cover_img from "../images/bedrooms_cover_img.jpg";
import bathrooms_cover_img from "../images/bathrooms_cover_img.jpg";
import kitchen_cover_img from "../images/kitchen_cover_img.jpg";
import dining_cover_img from "../images/dining_cover_img.jpg";
import exterior_cover_img from "../images/exterior_cover_img.jpg";
import { Link } from "react-router-dom";

function NewComplete() {
  const images = [
    { id: 1, url: home_cover_img },
    { id: 2, url: bedrooms_cover_img },
    { id: 3, url: bathrooms_cover_img },
    { id: 4, url: kitchen_cover_img },
    { id: 5, url: dining_cover_img },
    { id: 6, url: exterior_cover_img },
    { id: 7, url: home_cover_img },
    { id: 8, url: bedrooms_cover_img },
    { id: 9, url: bathrooms_cover_img },
  ];

  return (
    <>
      <div>
        <div className="px-6">
          {/* TOP HEADER */}
          <Header />
        </div>

        <div className="mt-[30px]">
          <div className=" bg-white rounded-t-[30px] px-6 pt-6 pb-16 h-screen">
            {/* TITLE */}
            <div className="text-center mt-[50px] mb-11">
              <h1 className="text-[32px] leading-6 font-bold">¡Terminaste!</h1>
            </div>

            {/* IMAGE GALLERY */}
            <ul className="grid grid-cols-3 gap-3">
              {images.map((image) => (
                <li key={image.id}>
                  <div className="bg-myGray rounded-[20px] h-[106px]">
                    <img
                      className="rounded-[20px] object-cover w-full h-full"
                      src={image.url}
                      alt="cover-img"
                    />
                  </div>
                </li>
              ))}
            </ul>

            {/* BUTTON */}
            <div className="mt-14">
              <div className="px-7 flex flex-col gap-y-3">
                <Link to="/profileWP">
                  <button className="bg-transparent w-full h-[45px] text-[20px] leading-7 text-myPurple-200 font-bold border-2 border-myPurple-200 rounded-[18px]">
                    Cambiar imágenes
                  </button>
                </Link>

                <Link to="/createAccountProfile">
                  <button className="bg-myPurple-200 w-full h-[45px] text-[20px] leading-7 text-myBlack font-bold rounded-[18px]">
                    Continuar
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewComplete;
