import React, { useState } from "react";
import back_icon from "../images/back_icon.svg";
import home_icon from "../images/home_icon.svg";
import home_icon_active from "../images/home_icon_active.svg";
import bedrooms_icon from "../images/bedrooms_icon.svg";
import bedrooms_icon_active from "../images/bedrooms_icon_active.svg";
import bathrooms_icon from "../images/bathrooms_icon.svg";
import bathrooms_icon_active from "../images/bathrooms_icon_active.svg";
import kitchen_icon from "../images/kitchen_icon.svg";
import kitchen_icon_active from "../images/kitchen_icon_active.svg";
import dining_icon from "../images/dining_icon.svg";
import dining_icon_active from "../images/dining_icon_active.svg";
import exterior_icon from "../images/exterior_icon.svg";
import exterior_icon_active from "../images/exterior_icon_active.svg";
import Header from "../components/Common/Header";
import HomeImages from "../components/New/HomeImages";
import BedroomsImages from "../components/New/BedroomsImages";
import BathroomsImages from "../components/New/BathroomsImages";
import KitchenImages from "../components/New/KitchenImages";
import DiningImages from "../components/New/DiningImages";
import ExteriorImages from "../components/New/ExteriorImages";
import HomeText from "../components/New/Slogan/HomeText";
import BedroomsText from "../components/New/Slogan/BedroomsText";
import BathroomsText from "../components/New/Slogan/BathroomsText";
import KitchenText from "../components/New/Slogan/KitchenText";
import DiningText from "../components/New/Slogan/DiningText";
import ExteriorText from "../components/New/Slogan/ExteriorText";
import { Link } from "react-router-dom";

function New() {
  const [home, setHome] = useState(true);
  const [bedrooms, setBedrooms] = useState(false);
  const [bathrooms, setBathrooms] = useState(false);
  const [kitchen, setKitchen] = useState(false);
  const [dining, setDining] = useState(false);
  const [exterior, setExterior] = useState(false);

  // FUNCTIONS
  function switchToHome() {
    setHome(true);
    setBedrooms(false);
    setBathrooms(false);
    setKitchen(false);
    setDining(false);
    setExterior(false);
  }

  function switchToBedrooms() {
    setHome(false);
    setBedrooms(true);
    setBathrooms(false);
    setKitchen(false);
    setDining(false);
    setExterior(false);
  }

  function switchToBathrooms() {
    setHome(false);
    setBedrooms(false);
    setBathrooms(true);
    setKitchen(false);
    setDining(false);
    setExterior(false);
  }

  function switchToKitchen() {
    setHome(false);
    setBedrooms(false);
    setBathrooms(false);
    setKitchen(true);
    setDining(false);
    setExterior(false);
  }

  function switchToDining() {
    setHome(false);
    setBedrooms(false);
    setBathrooms(false);
    setKitchen(false);
    setDining(true);
    setExterior(false);
  }

  function switchToExterior() {
    setHome(false);
    setBedrooms(false);
    setBathrooms(false);
    setKitchen(false);
    setDining(false);
    setExterior(true);
  }

  return (
    <>
      <div>
        <div className="px-6">
          {/* TOP HEADER */}
          <Header />
        </div>

        <div className="mt-[30px]">
          <div className=" bg-white rounded-t-[30px] px-6 pt-6 pb-16">
            {/* BACK BUTTON */}
            <Link to="/profileWP">
              <button className="flex items-center mb-7">
                <img src={back_icon} alt="back-icon" />
                <span className="ml-1.5 text-lg leading-6 font-bold">
                  Volver atrás
                </span>
              </button>
            </Link>

            {/* SELECT OPTIONS */}
            <div className="px-10 mb-5">
              <div className="flex gap-x-3">
                {/* HOME */}
                <button className="relative pb-4" onClick={switchToHome}>
                  {/* IMAGE */}
                  <img
                    src={home ? home_icon_active : home_icon}
                    alt="home-icon"
                  />
                  <div className={home ? "block" : "hidden"}>
                    <div className="flex absolute -bottom-[14px] left-1/2 -translate-x-1/2 -translate-y-1/2">
                      {/* TEXT */}
                      <span className="text-xs leading-5 font-medium">
                        Portada
                      </span>
                    </div>
                  </div>
                </button>

                {/* BEDROOMS */}
                <button className="relative pb-4" onClick={switchToBedrooms}>
                  {/* IMAGE */}
                  <img
                    src={bedrooms ? bedrooms_icon_active : bedrooms_icon}
                    alt="bedrooms-icon"
                  />
                  <div className={bedrooms ? "block" : "hidden"}>
                    {/* TEXT */}
                    <div className="flex absolute -bottom-[14px] left-1/2 -translate-x-1/2 -translate-y-1/2">
                      <span className="text-xs leading-5 font-medium">
                        Habitaciones
                      </span>
                    </div>
                  </div>
                </button>

                {/* BATHROOMS */}
                <button className="relative pb-4" onClick={switchToBathrooms}>
                  {/* IMAGE */}
                  <img
                    src={bathrooms ? bathrooms_icon_active : bathrooms_icon}
                    alt="bathrooms-icon"
                  />
                  <div className={bathrooms ? "block" : "hidden"}>
                    <div className="flex absolute -bottom-[14px] left-1/2 -translate-x-1/2 -translate-y-1/2">
                      {/* TEXT */}
                      <span className="text-xs leading-5 font-medium">
                        Baños
                      </span>
                    </div>
                  </div>
                </button>

                {/* KITCHEN */}
                <button className="relative pb-4" onClick={switchToKitchen}>
                  {/* IMAGE */}
                  <img
                    src={kitchen ? kitchen_icon_active : kitchen_icon}
                    alt="kitchen-icon"
                  />
                  <div className={kitchen ? "block" : "hidden"}>
                    <div className="flex absolute -bottom-[14px] left-1/2 -translate-x-1/2 -translate-y-1/2">
                      {/* TEXT */}
                      <span className="text-xs leading-5 font-medium">
                        Cocina
                      </span>
                    </div>
                  </div>
                </button>

                {/* DINING */}
                <button className="relative pb-4" onClick={switchToDining}>
                  {/* IMAGE */}
                  <img
                    src={dining ? dining_icon_active : dining_icon}
                    alt="dining-icon"
                  />
                  <div className={dining ? "block" : "hidden"}>
                    <div className="flex absolute -bottom-[14px] left-1/2 -translate-x-1/2 -translate-y-1/2">
                      {/* TEXT */}
                      <span className="text-xs leading-5 font-medium">
                        Sala/Comedor
                      </span>
                    </div>
                  </div>
                </button>

                {/* EXTERIOR */}
                <button className="relative pb-4" onClick={switchToExterior}>
                  {/* IMAGE */}
                  <img
                    src={exterior ? exterior_icon_active : exterior_icon}
                    alt="exterior-icon"
                  />
                  <div className={exterior ? "block" : "hidden"}>
                    <div className="flex absolute -bottom-[14px] left-1/2 -translate-x-1/2 -translate-y-1/2">
                      {/* TEXT */}
                      <span className="text-xs leading-5 font-medium">
                        Exteriores
                      </span>
                    </div>
                  </div>
                </button>
              </div>
            </div>

            {/* TEXT */}
            <div className="text-center mb-12">
              <h2 className="text-[22px] leading-6 font-bold">
                {home === true ? (
                  <HomeText />
                ) : bedrooms === true ? (
                  <BedroomsText />
                ) : bathrooms === true ? (
                  <BathroomsText />
                ) : kitchen === true ? (
                  <KitchenText />
                ) : dining === true ? (
                  <DiningText />
                ) : (
                  <ExteriorText />
                )}
              </h2>
            </div>

            {/* BUTTON */}
            <div className="px-[52px] mb-[30px] relative">
              <button className="bg-myPurple-200 w-full h-[45px] text-[20px] leading-7 text-myBlack font-bold rounded-[18px]">
                Abrir biblioteca
              </button>

              {/* INPUT */}
              <input
                className="opacity-0 w-60 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                type="file"
                id="img"
                name="img"
                accept="image/*"
              />
            </div>

            {/* IMAGE GALLERY */}
            <ul className="grid grid-cols-3 gap-3">
              {home === true ? (
                <HomeImages />
              ) : bedrooms === true ? (
                <BedroomsImages />
              ) : bathrooms === true ? (
                <BathroomsImages />
              ) : kitchen === true ? (
                <KitchenImages />
              ) : dining === true ? (
                <DiningImages />
              ) : (
                <ExteriorImages />
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default New;
