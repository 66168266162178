import { Route, Routes } from "react-router-dom";
import ProfileWP from "./pages/ProfileWP";
import ChooseColor from "./pages/ChooseColor";
import CreateAccount from "./pages/CreateAccount";
import CreateAccountInfo from "./pages/CreateAccountInfo";
import CreateAccountProfilePicture from "./pages/CreateAccountProfilePicture";
import GetIn from "./pages/GetIn";
import New from "./pages/New";

import NewComplete from "./pages/NewComplete";

function App() {
  const getUrl = window.location.pathname;

  if (getUrl === "/profileWP" || getUrl === "/new") {
    document.body.style.background = "#F8F8F8";
    document.body.style.color = "#1A1A1A";
  } else {
    document.body.style.background = "#1A1A1A";
    document.body.style.color = "#ffffff";
  }

  return (
    <>
      <div className="max-w-md m-auto bg-darkBlack">
        <Routes>
          <Route path="/" element={<GetIn />} />
          <Route path="/createAccount" element={<CreateAccount />} />
          <Route path="/createAccountInfo" element={<CreateAccountInfo />} />
          <Route
            path="/createAccountProfile"
            element={<CreateAccountProfilePicture />}
          />
          <Route path="/profileWP" element={<ProfileWP />} />
          <Route path="/new" element={<New />} />
          <Route path="/newComplete" element={<NewComplete/>} />
          <Route path="/chooseColor" element={<ChooseColor />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
