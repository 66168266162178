import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import menu from "../../images/menu.svg";

function Header() {
  return (
    <>
      <div className=" pt-11">
        <div className="flex items-center justify-between">
          <Link to="/">
            <div className="w-[106.22px] cursor-pointer">
              <img src={logo} alt="logo" />
            </div>
          </Link>
          <div className=" w-[50px] h-[50px] rounded-full bg-myBlack flex items-center justify-center cursor-pointer">
            <img src={menu} alt="menu" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
