import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { AiFillApple } from "react-icons/ai";
import { Link } from "react-router-dom";

function CreateAccount() {
  return (
    <>
      <div className="pt-[105px] px-8 pb-24">
        {/* TOP DETAILS */}
        <div className="mb-16">
          {/* HEADING */}
          <h2 className="text-[36px] leading-[24px] font-bold mb-6 text-center">
            Crea una cuenta
          </h2>

          {/* DESC */}
          <p className="text-[24px] leading-[26px] font-medium text-center">
            Escribe tu correo electrónico y tu contraseña
          </p>
        </div>

        {/* INPUT FIELDS */}
        <form className="flex flex-col mb-[106px]">
          <div className="space-y-10 mb-[60px]">
            <input
              className="w-full py-2.5 px-3 border-t-0 border-l-0 border-r-0 border-b-2 border-white bg-transparent placeholder:text-myPurple-200 placeholder:opacity-50 focus-visible:outline-0"
              placeholder="Email"
              type="email"
            />
            <input
              className="w-full py-2.5 px-3 border-t-0 border-l-0 border-r-0 border-b-2 border-white bg-transparent placeholder:text-myPurple-200 placeholder:opacity-50 focus-visible:outline-0"
              placeholder="Contraseña"
              type="password"
            />
          </div>

          {/* BUTTON */}
          <div className="px-7">
            <Link to="/createAccountInfo">
              <button className="bg-myPurple-200 w-full h-[45px] text-[20px] leading-7 text-myBlack font-bold rounded-[18px]">
                Crear cuenta
              </button>
            </Link>
          </div>
        </form>

        {/* BOTTOM DETAILS */}
        <div>
          {/* DESC */}
          <p className="text-[24px] leading-[26px] font-medium text-center mb-11">
            O regístrate a través de:
          </p>

          {/* ICONS */}
          <div className="flex gap-x-[22px] justify-center items-center">
            <div className="bg-white rounded-full w-[56px] h-[56px] relative cursor-pointer">
              <FcGoogle
                size={36}
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
              />
            </div>

            <div className="bg-[#3C5B9A] rounded-full w-[56px] h-[56px] relative cursor-pointer">
              <FaFacebookF
                size={36}
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
              />
            </div>

            <div className="bg-white rounded-full w-[56px] h-[56px] relative cursor-pointer">
              <AiFillApple
                size={36}
                color={"black"}
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateAccount;
