import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";

// function GetIn() {
//   return (
//     <>
//     {/* SPLASH-SCREEN */}

//       <div className="pt-24 px-11 pb-24">
//         {/* HEADING */}
//         <h2 className="text-[28px] leading-[30px] font-bold mb-12">
//           Welcome,
//           <br />
//           sign in to continiue
//         </h2>

//         {/* ICONS */}
//         <div className="flex justify-center items-center mb-12">
//           <div className="bg-[#D4493B] rounded-full w-[56px] h-[56px] relative cursor-pointer">
//             <AiOutlineGoogle
//               size={36}
//               className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
//             />
//           </div>

//           <div className="ml-10 bg-[#3C5B9A] rounded-full w-[56px] h-[56px] relative cursor-pointer">
//             <FaFacebookF
//               size={36}
//               className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
//             />
//           </div>
//         </div>

//         {/* INPUT FIELDS */}
//         <div className="flex flex-col mb-10">
//           <input
//             className="mb-9 pb-2 border-b-2 border-white bg-transparent placeholder:text-[#ffffff80] focus-visible:outline-0"
//             placeholder="Email"
//             type="email"
//           />
//           <input
//             className="pb-2 border-b-2 border-white bg-transparent placeholder:text-[#ffffff80] focus-visible:outline-0"
//             placeholder="Contraseña"
//             type="password"
//           />
//           <span className="text-[#ffffff80] text-center text-[14px] mt-2 cursor-pointer">
//             ¿Olvidaste tu contraseña?
//           </span>
//         </div>

//         {/* BUTTON */}
//         <div className="mb-14">
//           <div className="px-7">
//             <button className="bg-blue w-full text-[20px] font-bold py-2 rounded-full">
//               Iniciar sesión
//             </button>
//           </div>
//         </div>

//         {/* SIGN UP */}
//         <div className="text-[18px] leading-[24px] text-center font-medium">
//           ¿Todavía no tienes una cuenta?
//           <br />
//           ¡Regístrate{" "}
//           <Link
//             to="/createAccount"
//             className="underline underline-offset-[6px] cursor-pointer"
//           >
//             aquí
//           </Link>{" "}
//           ahora!
//         </div>
//       </div>
//     </>
//   );
// }
const GetIn = () => {
  // Loading state
  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    // Wait for 3 seconds
    setTimeout(() => {
      setisloading(false);
    }, 3000);
  }, []);

  return isloading ? (
    // If page is still loading then splash screen

    // SPLASH-SCREEN
    <div
      isloading={isloading}
      className={"h-screen flex items-center justify-center"}
    >
      <img src={logo} alt="logo-img" />
    </div>
  ) : (
    <div className="pt-[105px] px-8 pb-24">
      {/* TOP DETAILS */}
      <div className="mb-[70px]">
        {/* HEADING */}
        <h2 className="text-[36px] leading-[24px] font-bold mb-6 text-center">
          ¡Bienvenido!
        </h2>

        {/* DESC */}
        <p className="text-[24px] leading-[26px] font-medium text-center">
          Inicia sesión para continuar.
        </p>
      </div>
      {/* ICONS */}
      {/* <div className="flex justify-center items-center mb-12">
        <div className="bg-[#D4493B] rounded-full w-[56px] h-[56px] relative cursor-pointer">
          <AiOutlineGoogle
            size={36}
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </div>

        <div className="ml-10 bg-[#3C5B9A] rounded-full w-[56px] h-[56px] relative cursor-pointer">
          <FaFacebookF
            size={36}
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </div>
      </div> */}

      {/* INPUT FIELDS */}
      <form className="flex flex-col mb-28">
        <div className="space-y-10 mb-[18px]">
          <input
            className="w-full py-2.5 px-3 border-t-0 border-l-0 border-r-0 border-b-2 border-white bg-transparent placeholder:text-myPurple-200 placeholder:opacity-50 focus-visible:outline-0"
            placeholder="Email"
            type="email"
          />
          <input
            className="w-full py-2.5 px-3 border-t-0 border-l-0 border-r-0 border-b-2 border-white bg-transparent placeholder:text-myPurple-200 placeholder:opacity-50 focus-visible:outline-0"
            placeholder="Contraseña"
            type="password"
          />
        </div>
        <span className=" text-myPurple-200 opacity-50 text-center text-[16px] leading-7 cursor-pointer mb-4">
          ¿Olvidaste tu contraseña?
        </span>

        {/* BUTTON */}
        <div className="px-7">
          <button className="bg-myPurple-200 w-full h-[45px] text-[20px] leading-7 text-myBlack font-bold rounded-[18px]">
            Iniciar sesión
          </button>
        </div>
      </form>

      {/* SIGN UP */}
      <div className="text-[24px] leading-[30px] text-center font-medium mb-8">
        ¿Todavía no tienes una cuenta?
      </div>

      {/* BUTTON */}
      <div className="px-7">
        <Link to="/createAccount">
          <button className="bg-myPurple-200 w-full h-[45px] text-[20px] leading-7 text-myBlack font-bold rounded-[18px]">
            Regístrate aquí
          </button>
        </Link>
      </div>
    </div>
  );
};

export default GetIn;
