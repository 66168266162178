import React from "react";
import cover_img from "../../images/bathrooms_cover_img.jpg";

function BathroomsImages() {
  const images = [
    { id: 1, url: cover_img },
    { id: 2, url: cover_img },
    { id: 3, url: cover_img },
    { id: 4, url: cover_img },
    { id: 5, url: cover_img },
    { id: 6, url: cover_img },
    { id: 7, url: cover_img },
    { id: 8, url: cover_img },
    { id: 9, url: cover_img },
  ];

  return (
    <>
      {images.map((image) => (
        <li key={image.id}>
          <div className="bg-myGray rounded-[20px] h-[106px]">
            <img
              className="rounded-[20px] object-cover w-full h-full"
              src={image.url}
              alt="cover-img"
            />
          </div>
        </li>
      ))}
    </>
  );
}

export default BathroomsImages;
