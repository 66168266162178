import React from "react";

function BathroomsText() {
  return (
    <>
      <span>
        ¡Muy bien! Ahora elige
        <br />
        una imagen de los baños.
      </span>
    </>
  );
}

export default BathroomsText;
