import React from "react";

function HomeText() {
  return (
    <>
      <span>
        Elige una imagen de portada
        <br />
        para tu video.
      </span>
    </>
  );
}

export default HomeText;
