import React from "react";

function DiningText() {
  return (
    <>
      <span>
        ¡Perfecto! Elige ahora hasta dos
        <br />
        imágenes de sala y/o comedor.
      </span>
    </>
  );
}

export default DiningText;
