import React, { useState } from "react";
import logo from "../images/logo.png";
import advisoryProfileImg from "../images/profileImg.png";
import template1 from "../images/template1.png";

function ChooseColor() {
  const [purple, setpurple] = useState(true);
  const [blue, setblue] = useState(false);
  const [red, setred] = useState(false);
  const [yellow, setyellow] = useState(false);
  const [pink, setpink] = useState(false);
  const [green, setgreen] = useState(false);

  function purpleOnChange() {
    setpurple(true);
    setblue(false);
    setred(false);
    setyellow(false);
    setpink(false);
    setgreen(false);
  }

  function blueOnChange() {
    setpurple(false);
    setblue(true);
    setred(false);
    setyellow(false);
    setpink(false);
    setgreen(false);
  }

  function redOnChange() {
    setpurple(false);
    setblue(false);
    setred(true);
    setyellow(false);
    setpink(false);
    setgreen(false);
  }

  function yellowOnChange() {
    setpurple(false);
    setblue(false);
    setred(false);
    setyellow(true);
    setpink(false);
    setgreen(false);
  }

  function pinkOnChange() {
    setpurple(false);
    setblue(false);
    setred(false);
    setyellow(false);
    setpink(true);
    setgreen(false);
  }

  function greenOnChange() {
    setpurple(false);
    setblue(false);
    setred(false);
    setyellow(false);
    setpink(false);
    setgreen(true);
  }

  const ColorChanger = ({ color, active, inActive, clickFunction }) => {
    return (
      <>
        <div
          className={color ? active : inActive}
          onClick={() => clickFunction()}
          style={{ width: "37px", height: "37px", borderRadius: "9999px", cursor: "pointer" }}
        ></div>
      </>
    );
  };

  return (
    <>
      <div>
        {/* TOP HEADER */}
        <div className="px-[30px] h-[85px] flex items-center justify-between rounded-b-[18px] bg-myBlack">
          <img className="w-[136.46px] h-[14.86px]" src={logo} alt="logo-img" />
          <img
            className="w-[48px] h-[48px] rounded-full object-cover"
            src={advisoryProfileImg}
            alt="profile-img"
          />
        </div>

        <div className="px-[26px] pt-7 pb-24">
          <div className="flex items-center space-x-3.5 mb-7">
            <h3 className="text-[20px] font-bold">Propiedad inmobiliaria</h3>
            <span className="w-[52px] h-[2px] bg-white opacity-40 rounded-full"></span>
          </div>

          <div className="relative w-full h-[372px] rounded-[25px] mb-3.5">
            <img
              className="w-full h-full object-cover rounded-[25px]"
              src={template1}
              alt="template-img"
            />
            <div className="absolute -bottom-[3%] left-[32%] -translate-x-1/2 -translate-y-1/2 space-y-1">
              <h4 className="text-[22px] text-myBlack leading-[38px]">
                <span className="rounded bg-white px-1 py-0.5">
                  Departamento
                </span>{" "}
                <br />
                <span className="rounded bg-white px-1 py-0.5">
                  de 3 habitaciones
                </span>
              </h4>

              <div
                className={
                  purple === true ? "bg-gradient-purple" : blue === true ? "bg-gradient-blue" : red === true ? "bg-gradient-red" : yellow === true ? "bg-gradient-yellow" : pink === true ? "bg-gradient-pink" : green === true ? "bg-gradient-green" : "bg-gradient-purple"
                }
                style={{
                  borderRadius: "4px",
                  padding: "0 0.75rem",
                  width: "fit-content",
                }}
              >
                <h4 className="text-[22px]"> $8,500,000</h4>
              </div>
            </div>
          </div>

          <div>
            <h6 className="text-[18px] font-bold mb-4">Choose primary color</h6>

            <div className="flex flex-wrap space-x-2.5 mb-6">
              {/* <div
                className={
                  purple
                    ? "w-[37px] h-[37px] rounded-full bg-gradient-purple border-[1.5px] border-white"
                    : "w-[37px] h-[37px] rounded-full bg-gradient-purple"
                }
                onClick={() => purpleOnChange()}
              ></div> */}

              <ColorChanger
                color={purple}
                active={"bg-gradient-purple border-[1.5px] border-white"}
                inActive={"bg-gradient-purple"}
                clickFunction={purpleOnChange}
              />
              <ColorChanger
                color={blue}
                active={"bg-gradient-blue border-[1.5px] border-white"}
                inActive={"bg-gradient-blue"}
                clickFunction={blueOnChange}
              />
              <ColorChanger
                color={red}
                active={"bg-gradient-red border-[1.5px] border-white"}
                inActive={"bg-gradient-red"}
                clickFunction={redOnChange}
              />
              <ColorChanger
                color={yellow}
                active={"bg-gradient-yellow border-[1.5px] border-white"}
                inActive={"bg-gradient-yellow"}
                clickFunction={yellowOnChange}
              />
              <ColorChanger
                color={pink}
                active={"bg-gradient-pink border-[1.5px] border-white"}
                inActive={"bg-gradient-pink"}
                clickFunction={pinkOnChange}
              />
              <ColorChanger
                color={green}
                active={"bg-gradient-green border-[1.5px] border-white"}
                inActive={"bg-gradient-green"}
                clickFunction={greenOnChange}
              />
            </div>

            <div className="px-7">
              <button className="bg-blue w-full text-[20px] py-2 rounded-full">
                Next
              </button>
            </div>

            {/* <div
              className={
                blue === true ? "blue" : green === true ? "green" : "red"
              }
            >
              <h1>blue</h1>
            </div> */}

            {/* <div>
              <h1 onClick={() => blueOnChange()}>blue</h1>
              <h1 onClick={() => greeOnChange()}>Green</h1>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ChooseColor;
