import React from "react";

function BedroomsText() {
  return (
    <>
      <span>
        Ahora, elige hasta dos imágenes
        <br />
        de las habitaciones.
      </span>
    </>
  );
}

export default BedroomsText;
