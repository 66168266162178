import React from "react";
import profileImg from "../images/profileImg.png";
import browser from "../images/browser.svg";
import profileHome from "../images/profileHome.svg";
import message from "../images/message.svg";
import add_icon from "../images/add_icon.svg";
import { Link } from "react-router-dom";
import Header from "../components/Common/Header";

function ProfileWP() {

  return (
    <>
      <div className="px-6">
        {/* TOP HEADER */}
        <Header/>

        {/* PROFILE SECTION */}
        <div className="mt-12 bg-white rounded-[30px] flex flex-col px-6 pb-5">
          <div className="w-[77px] h-[77px] rounded-full border-[6px] border-white mx-auto -mt-11 mb-2">
            <img src={profileImg} alt="profile-img" />
          </div>
          <div className=" flex flex-col mx-auto text-center mb-5">
            <h2 className=" text-2xl leading-6 text-myBlack font-bold">
              Ana Gallardo
            </h2>
            <h6 className=" text-lg font-medium text-myPurple-200">
              Grupo Fratelli
            </h6>
          </div>
          <div className="flex">
            <div className="flex-1">
              <div className="mr-auto w-14 h-14 rounded-[18px] bg-myBlack flex items-center justify-center cursor-pointer">
                <img src={browser} alt="browser-img" />
              </div>
            </div>

            <div className="flex-1">
              <div className="flex flex-col items-center justify-center">
                <img className="w-6 h-6" src={profileHome} alt="home-img" />
                <div className=" text-xl font-extraBold">0</div>
              </div>
            </div>

            <div className="flex-1">
              <div className="ml-auto w-14 h-14 rounded-[18px] bg-myBlack flex items-center justify-center cursor-pointer">
                <img src={message} alt="message-img" />
              </div>
            </div>
          </div>
        </div>

        {/* DESC */}
        <p className="mt-44 text-center text-base leading-[18px] text-myPurple-200">
          Tus propiedades aparecerán aquí una vez que hayas creado tu primer
          video.
        </p>

        {/* ADD BUTTON */}
        <button className="fixed left-1/2 bottom-3 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-y-2.5">
          <Link
            className="w-[80px] h-[80px] rounded-full bg-myBlack flex items-center justify-center"
            to="/new"
          >
            <img src={add_icon} alt="add-img"/>
          </Link>

          <div className=" text-xl text-myPurple-200 font-bold">
            Subir propiedad
          </div>
        </button>
      </div>
    </>
  );
}

export default ProfileWP;
